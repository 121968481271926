export default {
  backup: 'Back-up',
  downloadTheProjectBackupFile: 'Download het back-upbestand van het project (in json)',
  restoreProject: 'Herstellen...',
  incorrectUsrPwd: 'Onjuiste gebruikersnaam of wachtwoord',
  askDeleteUser: 'Wilt u deze gebruiker echt verwijderen?',
  askDeleteTemp: 'Wilt u deze sjabloon echt verwijderen?',
  askDeleteEnv: 'Wilt u deze omgeving echt verwijderen?',
  askDeleteInv: 'Wilt u deze inventaris echt verwijderen?',
  askDeleteKey: 'Wilt u deze sleutel echt verwijderen?',
  askDeleteRepo: 'Wilt u dit repository echt verwijderen?',
  askDeleteProj: 'Wilt u dit project echt verwijderen?',
  askDeleteTMem: 'Wilt u dit teamlid echt verwijderen?',
  edit: 'Bewerken',
  nnew: 'Nieuw',
  keyFormSshKey: 'SSH-sleutel',
  keyFormLoginPassword: 'Inloggen met wachtwoord',
  keyFormNone: 'Geen',
  incorrectUrl: 'Onjuiste URL',
  username: 'Gebruikersnaam',
  username_required: 'Gebruikersnaam is verplicht',
  dashboard: 'Dashboard',
  history: 'Geschiedenis',
  activity: 'Activiteit',
  settings: 'Instellingen',
  signIn: 'Aanmelden',
  password: 'Wachtwoord',
  changePassword: 'Wachtwoord wijzigen',
  editUser: 'Gebruiker bewerken',
  newProject: 'Nieuw project',
  close: 'Sluiten',
  newProject2: 'Nieuw project...',
  demoMode: 'DEMO-MODUS',
  task: 'Taak #{expr}',
  youCanRunAnyTasks: 'U kunt alle taken uitvoeren',
  youHaveReadonlyAccess: 'U heeft alleen-lezen toegang',
  taskTemplates: 'Taaksjablonen',
  inventory: 'Inventaris',
  environment: 'Omgeving',
  keyStore: 'Sleutelopslag',
  repositories: 'Repositories',
  darkMode: 'Donkere modus',
  team: 'Team',
  users: 'Gebruikers',
  editAccount: 'Account bewerken',
  signOut: 'Afmelden',
  error: 'Fout',
  refreshPage: 'Pagina vernieuwen',
  relogin: 'Opnieuw aanmelden',
  howToFixSigninIssues: 'Hoe aanmeldingsproblemen op te lossen',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: 'Allereerst hebt u toegang nodig tot de server waar Semaphore draait.',
  executeTheFollowingCommandOnTheServerToSeeExisting: 'Voer het volgende commando uit op de server om bestaande gebruikers te zien:',
  semaphoreUserList: 'semaphore user list',
  youCanChangePasswordOfExistingUser: 'U kunt het wachtwoord van een bestaande gebruiker wijzigen:',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore user change-by-login --login user123 --password {makePasswordExample}',
  orCreateNewAdminUser: 'Of maak een nieuwe beheerder aan:',
  close2: 'Sluiten',
  semaphore: 'SEMAFOOR',
  dontHaveAccountOrCantSignIn: 'Hebt u geen account of kunt u zich niet aanmelden?',
  password2: 'Wachtwoord',
  cancel: 'Annuleren',
  noViews: 'Geen weergaven',
  addView: 'Weergave toevoegen',
  editEnvironment: 'Omgeving bewerken',
  deleteEnvironment: 'Omgeving verwijderen',
  environment2: 'Omgeving',
  newEnvironment: 'Nieuwe omgeving',
  environmentName: 'Omgevingsnaam',
  extraVariables: 'Extra variabelen',
  enterExtraVariablesJson: 'Voer extra variabelen in JSON opmaak in...',
  environmentVariables: 'Omgevingsvariabelen',
  enterEnvJson: 'Voer omgevings-JSON in...',
  environmentAndExtraVariablesMustBeValidJsonExample: 'Omgeving en extra variabelen moeten geldige JSON zijn. Voorbeeld:',
  dashboard2: 'Dashboard',
  ansibleSemaphore: 'Ansible Semaphore',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: 'Het spijt ons, maar <%= htmlWebpackPlugin.options.title %> werkt niet correct zonder ingeschakelde JavaScript. Schakel het in om door te gaan.',
  deleteInventory: 'Inventaris verwijderen',
  newInventory: 'Nieuwe inventaris',
  name: 'Naam',
  userCredentials: 'Gebruikersreferenties',
  sudoCredentialsOptional: 'Sudo-referenties (optioneel)',
  type: 'Type',
  pathToInventoryFile: 'Pad naar inventarisbestand',
  enterInventory: 'Voer inventaris in...',
  staticInventoryExample: 'Voorbeeld van statische inventaris:',
  staticYamlInventoryExample: 'Voorbeeld van statische YAML-inventaris:',
  keyName: 'Sleutelnaam',
  loginOptional: 'Inloggen (optioneel)',
  usernameOptional: 'Gebruikersnaam (optioneel)',
  privateKey: 'Privésleutel',
  override: 'Overschrijven',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: 'Gebruik dit type sleutel voor HTTPS-repositories en voor playbooks die geen SSH-verbindingen gebruiken.',
  deleteKey: 'Sleutel verwijderen',
  newKey: 'Nieuwe sleutel',
  create: 'Maken',
  newTask: 'Nieuwe taak',
  cantDeleteThe: 'Kan {objectTitle} niet verwijderen',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: 'De {objectTitle} kan niet worden verwijderd omdat deze wordt gebruikt door de onderstaande resources',
  projectName: 'Projectnaam',
  allowAlertsForThisProject: 'Waarschuwingen toestaan voor dit project',
  telegramChatIdOptional: 'Telegram Chat ID (optioneel)',
  maxNumberOfParallelTasksOptional: 'Maximaal aantal gelijktijdige taken (optioneel)',
  deleteRepository: 'Repository verwijderen',
  newRepository: 'Nieuwe repository',
  urlOrPath: 'URL of pad',
  absPath: 'Absoluut pad',
  branch: 'Tak',
  accessKey: 'Toegangssleutel',
  credentialsToAccessToTheGitRepositoryItShouldBe: 'Referenties om toegang te krijgen tot het Git-repository. Het zou moeten zijn:',
  ifYouUseGitOrSshUrl: 'als u Git- of SSH-URL gebruikt.',
  ifYouUseHttpsOrFileUrl: 'als u HTTPS- of bestand-URL gebruikt.',
  none: 'Geen',
  ssh: 'SSH',
  deleteProject: 'Project verwijderen',
  save: 'Opslaan',
  deleteProject2: 'Project verwijderen',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: 'Zodra u een project verwijdert, is er geen weg terug. Wees zeker.',
  name2: 'Naam *',
  title: 'Titel *',
  description: 'Beschrijving',
  required: 'Vereist',
  key: '{expr}',
  surveyVariables: 'Enquêtevariabelen',
  addVariable: 'Variabele toevoegen',
  columns: 'Kolommen',
  buildVersion: 'Bouwversie',
  messageOptional: 'Bericht (optioneel)',
  debug: 'Debug',
  dryRun: 'Proefrun',
  diff: 'Verschil',
  advanced: 'Geavanceerd',
  hide: 'Verbergen',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: 'Sta het overschrijven van CLI-argument toe in de instellingen van het taaksjabloon',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'CLI-argumenten (JSON-array). Voorbeeld: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  started: 'Gestart',
  author: 'Auteur',
  duration: 'Duur',
  stop: 'Stoppen',
  forceStop: 'Gedwongen stop',
  deleteTeamMember: 'Teamlid verwijderen',
  team2: 'Team',
  newTeamMember: 'Nieuw teamlid',
  user: 'Gebruiker',
  administrator: 'Beheerder',
  definesStartVersionOfYourArtifactEachRunIncrements: 'Definieert de startversie van uw artefact. Elke uitvoering verhoogt de artefactversie.',
  forMoreInformationAboutBuildingSeeThe: 'Voor meer informatie over bouwen, zie de',
  taskTemplateReference: 'Referentie taaksjabloon',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: 'Definieert welk artefact moet worden geïmplementeerd wanneer de taak wordt uitgevoerd.',
  forMoreInformationAboutDeployingSeeThe: 'Voor meer informatie over implementeren, zie de',
  taskTemplateReference2: 'Referentie taaksjabloon',
  definesAutorunSchedule: 'Definieert autorunschema.',
  forMoreInformationAboutCronSeeThe: 'Voor meer informatie over cron, zie de',
  cronExpressionFormatReference: 'Referentieformaat cron-uitdrukking',
  startVersion: 'Startversie',
  example000: 'Voorbeeld: 0.0.0',
  buildTemplate: 'Bouwsjabloon',
  autorun: 'Automatisch uitvoeren',
  playbookFilename: 'Playbook-bestandsnaam *',
  exampleSiteyml: 'Voorbeeld: site.yml',
  inventory2: 'Inventaris *',
  repository: 'Repository',
  environment3: 'Omgeving *',
  vaultPassword: 'Vault-wachtwoord',
  vaultPassword2: 'Vault-wachtwoord',
  view: 'Weergave',
  cron: 'Cron',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: 'Ik wil een taak uitvoeren via cron alleen voor nieuwe commits van een bepaald repository',
  repository2: 'Repository',
  cronChecksNewCommitBeforeRun: 'Cron controleert nieuwe commit voor uitvoering',
  readThe: 'Lees de',
  toLearnMoreAboutCron: 'om meer te weten te komen over Cron.',
  suppressSuccessAlerts: 'Onderdruk succesmeldingen',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'CLI-argumenten (JSON-array). Voorbeeld: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: 'Toestaan van CLI-argumenten in taak',
  docs: 'documentatie',
  editViews: 'Weergaven bewerken',
  newTemplate: 'Nieuw sjabloon',
  taskTemplates2: 'Taaksjablonen',
  all: 'Alles',
  notLaunched: 'Niet gestart',
  by: 'door {user_name}',
  editTemplate: 'Sjabloon bewerken',
  newTemplate2: 'Nieuw sjabloon',
  deleteTemplate: 'Sjabloon verwijderen',
  playbook: 'Playbook',
  email: 'E-mail',
  adminUser: 'Beheerdersgebruiker',
  sendAlerts: 'Meldingen verzenden',
  deleteUser: 'Gebruiker verwijderen',
  newUser: 'Nieuwe gebruiker',
  re: 'Her{getActionButtonTitle}',
  teamMember: '{expr} Teamlid',
  taskId: 'Taak-ID',
  version: 'Versie',
  status: 'Status',
  start: 'Starten',
  actions: 'Acties',
  alert: 'Melding',
  admin: 'Beheerder',
  role: 'Rol',
  external: 'Extern',
  time: 'Tijd',
  path: 'Pad',
  gitUrl: 'Git-URL',
  sshKey: 'SSH-sleutel',
  lastTask: 'Laatste taak',
  task2: 'Taak',
  build: 'Bouwen',
  deploy: 'Implementeren',
  run: 'Uitvoeren',
  add: 'Toevoegen',
  password_required: 'Wachtwoord is verplicht',
  name_required: 'Naam is verplicht',
  user_credentials_required: 'Gebruikersreferenties zijn verplicht',
  type_required: 'Type is verplicht',
  path_required: 'Pad naar inventarisbestand is verplicht',
  private_key_required: 'Privésleutel is verplicht',
  project_name_required: 'Projectnaam is verplicht',
  repository_required: 'Repository is verplicht',
  branch_required: 'Tak is verplicht',
  key_required: 'Sleutel is verplicht',
  user_required: 'Gebruiker is verplicht',
  build_version_required: 'Bouwversie is verplicht',
  title_required: 'Titel is verplicht',
  isRequired: 'is verplicht',
  mustBeInteger: 'Moet een geheel getal zijn',
  mustBe0OrGreater: 'Moet 0 of groter zijn',
  start_version_required: 'Startversie is verplicht',
  playbook_filename_required: 'Playbook-bestandsnaam is verplicht',
  inventory_required: 'Inventaris is verplicht',
  environment_required: 'Omgeving is verplicht',
  email_required: 'E-mail is verplicht',
  build_template_required: 'Bouwsjabloon is verplicht',
  Task: 'Taak',
  Build: 'Bouwen',
  Deploy: 'Implementeren',
  Run: 'Uitvoeren',
  CreateDemoProject: 'Demo-project aanmaken',
  LeaveProject: 'Project verlaten',
};
